import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink as NavLink } from 'react-router-hash-link';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 150) { 
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className="container">
      <div className="text-center mt-2">
        <Link className="navbar-brand" to="/">
          <img src={`/images/logo/logo.webp`} alt="Logo" className="logo" />
        </Link>
        <h2 className="mt-2 green font-dancing-script-header">Budi jača od svojih izgovora uz aloe vera</h2>
      </div>

      <div className={`container navigation ${isFixed ? 'fixed-top' : ''}`}>
        <nav className="navbar navbar-expand-lg navbar-light justify-content-center text-center">
          <div className="">
            <div className="ml-auto d-lg-none fade-in-element">
              <button className="btn-menu-mobile custom-toggler p-0" type="button" onClick={toggleMenu}>
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div className={`collapse navbar-collapse ${isOpen ? 'show fade-in-element' : ''}`} id="navbarNav">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item me-md-3">
                  <NavLink className="nav-link font-dancing-script navigation-font" smooth to="/" onClick={closeMenu}>Početna</NavLink>
                </li>
                <li className="nav-item me-md-3">
                  <NavLink className="nav-link font-dancing-script navigation-font" smooth to="/products" onClick={closeMenu}>Proizvodi</NavLink>
                </li>
                <li className="nav-item me-md-3">
                  <NavLink className="nav-link font-dancing-script navigation-font" smooth to="https://flpshop.rs/pretraga" target="_blank"  rel="noopener noreferrer" onClick={closeMenu}>Prodavnica</NavLink>
                </li>
                <li className="nav-item me-md-3">
                  <NavLink className="nav-link font-dancing-script navigation-font" smooth to="/about-me" onClick={closeMenu}>O meni</NavLink>
                </li>
                <li className="nav-item me-md-3">
                  <NavLink className="nav-link font-dancing-script navigation-font" smooth to="/business-cooperation" onClick={closeMenu}>Poslovna saradnja</NavLink>
                </li>
                <li className="nav-item me-md-3">
                  <NavLink className="nav-link font-dancing-script navigation-font" smooth to="/contact" onClick={closeMenu}>Kontakt</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </section>
  );
}

export default Header;